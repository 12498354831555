import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField/TextField";

const styles = theme => ({
    button: {
        color: "white!important",
        background: "#101ba5!important",
        borderRadius: "25px!important",
        padding: "6px 20px!important",
    },
    buttonWrapper: {
        display: 'inline'
    },
    required: {
        fontFamily: 'Roboto, Helvetica, Arial sans-serif !important',
        color: '#c00',
        lineHeight: '2rem'
    },
    textarea: {
        width: '100%',
        height: 100
    },
});

class RejectDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            displayErrors: false,
            rejectionReason: ''
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleClickOpen = () => {
        this.setState({open: true});
    };

    handleCancel = () => {
        this.setState({open: false});
    };

    handleSubmit(event) {
        const {handleRejectReason} = this.props;
        event.preventDefault();

        if ('' === event.target.rejectionReason.value) {
            this.setState({displayErrors: true});
            return;
        }

        this.setState({open: false});
        this.setState({displayErrors: false});
        handleRejectReason(event.target.rejectionReason.value);
    }

    render() {
        const {classes} = this.props;
        const {displayErrors} = this.state;

        return (
            <div className={classes.buttonWrapper}>
                <Button className={classes.button} onClick={this.handleClickOpen}>
                    Reject
                </Button>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">{"Reason?"}</DialogTitle>
                    <DialogContent>
                        <form id="rejectReasonForm" action="/" method="POST" onSubmit={this.handleSubmit}>
                            <TextField name="rejectionReason" autoFocus/>
                            <br/>
                            {displayErrors && <span className={classes.required}>Rejection reason is required.</span>}
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCancel} className={classes.button}>Cancel</Button>
                        <Button type="submit" form="rejectReasonForm" className={classes.button}>Reject Time
                            Card</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default (withStyles(styles)(RejectDialog));
