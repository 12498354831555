import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import CircularIndeterminate from './CircularIndeterminate';

const styles = theme => ({
    loading: {
        display: 'flex',
        width: '100%',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
    },
});

class FullPageLoading extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
    };

    render() {
        const {classes} = this.props;

        return (
            <div className={classes.loading}><CircularIndeterminate /></div>
        );
    }
}

export default withStyles(styles)(FullPageLoading);