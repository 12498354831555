import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
    button: {
        color: "white!important",
        background: "#101ba5!important",
        borderRadius: "25px!important",
        padding: "6px 20px!important",
    },
    buttonWrapper: {
        display: 'inline'
    },
});

class ConfirmationDialog extends React.Component {
    state = {
        open: false,
    };

    handleClickOpen = () => {
        this.setState({open: true});
    };

    handleCancel = () => {
        this.setState({open: false});
    };

    render() {
        const {classes, fullScreen, handleSubmit} = this.props;

        return (
            <div className={classes.buttonWrapper}>
                <Button className={classes.button} onClick={this.handleClickOpen}>
                    Submit
                </Button>
                <Dialog
                    fullScreen={fullScreen}
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">{"Submit Time Card?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you are ready to sumit this time card?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCancel} className={classes.button}>Cancel</Button>
                        <Button onClick={handleSubmit} className={classes.button} autoFocus>Submit Time Card</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

ConfirmationDialog.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
};

export default withMobileDialog()(withStyles(styles)(ConfirmationDialog));
