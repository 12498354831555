import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {loadUser} from 'redux-oidc';
import FullPageLoading from '../component/FullPageLoading';
import store from '../store';
import userManager from '../utils/userManager';
import HomePage from './HomePage';

class Guard extends Component {
    static propTypes = {
        user: PropTypes.object,
        isLoadingUser: PropTypes.bool.isRequired,
    };

    state = {
        loading: true,
    };

    initialLoad = true;

    render() {
        const {loading} = this.state;

        return (
            <div>
                {loading ? <FullPageLoading/> : <HomePage/>}
            </div>
        );
    }

    componentDidMount() {
        if (!this.props.isLoadingUser && this.props.user && !this.props.user.expired) {
            this.setState({loading: false});
            return;
        }

        loadUser(store, userManager).then((user) => {
            if (null !== user) {
                this.setState({loading: false});
                this.initialLoad = false;
                return;
            }

            userManager.clearStaleState();

            userManager.signinSilent().then(() => {
                this.setState({loading: false});
                this.initialLoad = false;
            }).catch(() => {
                userManager.signinRedirect({
                    data: {
                        returnTo: window.location.pathname + window.location.search + window.location.hash
                    },
                });
            });
        });
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if (!nextProps.isLoadingUser && (!nextProps.user || nextProps.user.expired) && !this.initialLoad) {
            userManager.signinRedirect({
                data: {
                    returnTo: window.location.pathname + window.location.search + window.location.hash
                },
            });
        }
    }
}

const mapStateToProps = state => ({
    user: state.oidc.user,
    isLoadingUser: state.oidc.isLoadingUser,
});

export default connect(mapStateToProps)(Guard);
