import {Component} from 'react';
import {processSilentRenew} from 'redux-oidc';

class SilentRenew extends Component {
    render() {
        processSilentRenew();
        return '';
    }
}

export default SilentRenew;
