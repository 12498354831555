import {
    FETCH_DATA_BEGIN,
    FETCH_DATA_SUCCESS,
    FETCH_DATA_FAILURE,
    UPDATE_PER_PAGE,
    UPDATE_SORT,
    RESET_DATA
} from './DataActions';

const initialState = {
    items: {
        start: 0,
        count: 0,
        totalCount: 0,
        totalPages: 0,
        links: [],
        data: [],
    },
    orderBy: 'week-begin',
    order: 'asc',
    page: 1,
    rowsPerPage: 5,
    loading: true,
    error: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DATA_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
            };

        case FETCH_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.payload.data,
            };

        case FETCH_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };

        case UPDATE_PER_PAGE:
            return {
                ...state,
                items: initialState.items,
                perPage: action.payload.perPage,
            };

        case UPDATE_SORT:
            return {
                ...state,
                orderBy: action.payload.orderBy,
                order: action.payload.order,
            };

        case RESET_DATA:
            return initialState;

        default:
            return state;
    }
}
