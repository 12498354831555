import React from 'react';
import {Tabs, Typography, withStyles} from '@material-ui/core';
import Card from '@material-ui/core/Card/Card';
import CardContent from '@material-ui/core/CardContent/CardContent';

import AppBar from '@material-ui/core/AppBar/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline/CssBaseline';
import Tab from '@material-ui/core/Tab';
import LogoColor from '../asset/atr-logo.png';
import {Route, Switch, withRouter} from 'react-router';
import FullPageLoading from '../component/FullPageLoading';
import Detail from "./time-card/Detail";
import AddTimeCard from "./time-card/AddTimeCard";
import NotFound from "./NotFound";
import List from "./time-card/List";
import Reports from "./time-card/Reports";
import {fetchClient} from './../redux/client/ClientActions'
import {connect} from "react-redux";

const styles = theme => ({
    errorText: {
        width:300,
        textAlign:'center'
    },
    header: {
        display: 'flex',
        padding: theme.spacing.unit * 2,
        filter: "progid:DXImageTransform.Microsoft.gradient( startColorstr='#10238a', endColorstr='#1a8a44',GradientType=1 )",
        backgroundColor: "#10238a",
        background: "-moz-linear-gradient(left, #10238a 0%, #1a8a44 100%), -webkit-linear-gradient(left, #10238a 0%,#1a8a44 100%), linear-gradient(to right, #10238a 0%,#1a8a44 100%)",
    },
    headerLeft: {
        flexGrow: 1,
    },
    headerRight: {
        textAlign: 'right',
    },
    logo: {
        height: 50
    },
    main: {
        padding: theme.spacing.unit * 2,
    },
    typography: {
        color: "#ffffff",
    },
});

class HomePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            forceSearch: true,
            employeeName: '',
            employeePosition: '',
            submitted: '',
            weekBegin: '',
            approved: '',
            client: '',
        };

        this.searchTimeCards = this.searchTimeCards.bind(this);
    }

    updateSearchCriteria = (employeeName, employeePosition, submitted, weekBegin, approved, client) => {
        this.setState({
            employeeName: employeeName,
            employeePosition: employeePosition,
            submitted: submitted,
            weekBegin: weekBegin,
            approved: approved,
            client: client,
        });
    };

    turnOffForceSearch = () => {
        this.setState({
            forceSearch: false,
        });
    };

    handlePageChange = (event, value) => {
        this.props.history.push(value);
    };

    searchTimeCards(field, value) {
        this.setState({
            forceSearch: true,
            submitted: value
        });
    }

    downloadUserGuide() {
        window.open('/downloads/eTime User Guide.pdf');
    }

    downloadManagerGuide() {
        window.open('/downloads/usermanual-manager.pdf');
    }

    render() {
        const {loading, error, classes, client} = this.props;

        let selected = this.getSelectedTab();

        if (['/time-card', '/time-cards', '/add-time-card', '/'].indexOf(selected) === -1) {
            selected = false;
        }

        if (null !== error) {
            return <div>
                <CssBaseline/>

                <header className={classes.header}>
                    <div className={classes.headerLeft}>
                        <img src={LogoColor} height={100} className={classes.logo} alt="Soliant.cloud"/>
                    </div>
                </header>

                <AppBar position="static" color="secondary">
                    <Tabs value={selected} onChange={this.handlePageChange} indicatorColor="primary">
                        <Tab label="Login" value="/signout"/>
                    </Tabs>
                </AppBar>

                <main className={classes.main}>
                    <Card>
                        <CardContent>
                            <Typography variant="h4" gutterBottom component="h5" className={classes.errorText}>
                                Error: A user could not be found with the entered username and password combination. Please try again or reset your password.
                            </Typography>

                        </CardContent>
                    </Card>
                </main>
            </div>;
        }

        if (loading || null === client) {
            return <FullPageLoading/>;
        }

        return (
            <div>
                <CssBaseline/>

                <header className={classes.header}>
                    <div className={classes.headerLeft}>
                        <img src={LogoColor} height={100} className={classes.logo} alt="Soliant.cloud"/>
                    </div>
                    <div className={classes.headerRight}>
                        <Typography variant="h5" className={classes.typography}>Time Cards</Typography>
                    </div>
                </header>

                <AppBar position="static" color="secondary">
                    <Tabs value={selected} onChange={this.handlePageChange} indicatorColor="primary">
                        {'Manager' === client.role && (<Tab label="Search/List" value="/"/>)}
                        {'Contractor' === client.role && (<Tab label="Time Cards" value="/"/>)}
                        {selected === '/time-card' && (
                            <Tab label="Time Card Detail" value="/time-card" disabled={true}/>
                        )}
                        {'Contractor' === client.role && (<Tab label="Add Time Card" value="/add-time-card"/>)}
                        <Tab label="User Guide" onClick={this.downloadUserGuide}/>
                        {'Manager' === client.role && (<Tab label="Manager Guide" onClick={this.downloadManagerGuide}/>)}
                        <Tab label="Logout" value="/signout"/>
                    </Tabs>
                </AppBar>

                <main className={classes.main}>
                    <Switch>
                        <Route path="/" exact render={(props) => {
                            return (<List
                                    history={props.history}
                                    location={props.location}
                                    forceSearch={this.state.forceSearch}
                                    employeeName={this.state.employeeName}
                                    employeePosition={this.state.employeePosition}
                                    submitted={this.state.submitted}
                                    weekBegin={this.state.weekBegin}
                                    approved={this.state.approved}
                                    client={this.state.client}
                                    turnOffForceSearch={this.turnOffForceSearch}
                                    updateSearchCriteria={this.updateSearchCriteria}
                                    searchTimeCards={this.searchTimeCards}
                                />
                            )
                        }}/>
                        <Route path="/time-cards" exact component={List}/>
                        <Route path="/time-card/:projectId/:weekBegin" component={Detail}/>
                        <Route path="/add-time-card" component={AddTimeCard}/>
                        <Route path="/reports" component={Reports}/>
                        <Route path="*" exact component={NotFound}/>
                    </Switch>
                </main>
            </div>
        );
    }

    getSelectedTab() {
        return this.props.location.pathname.split('/').slice(0, 2).join('/');
    }

    componentDidMount() {
        const {dispatch} = this.props;

        if (null === this.props.client) {
            dispatch(fetchClient());
        }
    }
}

const mapStateToProps = function (state) {
    return ({
        client: state.client.client,
        error: state.client.error,
    })
};

export default (withStyles(styles)(withRouter(connect(mapStateToProps)(HomePage))));
