import React, {Component} from 'react';
import {SignoutCallbackComponent} from 'redux-oidc';
import FullPageLoading from '../component/FullPageLoading';
import userManager from '../utils/userManager';

class SignoutCallback extends Component {
    successHandler = () => {
        this.props.history.push('/');
    };

    render() {
        return (
            <SignoutCallbackComponent
                userManager={userManager}
                successCallback={this.successHandler}
            >
                <FullPageLoading/>
            </SignoutCallbackComponent>
        );
    }
}

export default SignoutCallback;
