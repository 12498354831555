import {request} from '../../api/ApiRequest';

export const FETCH_CLIENT_BEGIN = 'FETCH_CLIENT_BEGIN';
export const FETCH_CLIENT_SUCCESS = 'FETCH_CLIENT_SUCCESS';
export const FETCH_CLIENT_FAILURE = 'FETCH_CLIENT_FAILURE';

export const fetchClientBegin = () => ({
    type: FETCH_CLIENT_BEGIN,
});

export const fetchClientSuccess = client => ({
    type: FETCH_CLIENT_SUCCESS,
    payload: {client},
});

export const fetchClientFailure = error => ({
    type: FETCH_CLIENT_FAILURE,
    payload: {error},
});

export const fetchClient = () => {
    return dispatch => {
        dispatch(fetchClientBegin());

        request('get', '/client').then((response) => {
            dispatch(fetchClientSuccess(response.data));
        }).catch(error => {
            if (!error.response) {
                dispatch(fetchClientFailure('Unknown error'));
                return;
            }

            dispatch(fetchClientFailure(error.response.data));
        });
    };
};

