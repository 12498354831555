import {request} from '../../api/ApiRequest';

export const FETCH_Projects_BEGIN = 'FETCH_Projects_BEGIN';
export const FETCH_Projects_SUCCESS = 'FETCH_Projects_SUCCESS';
export const FETCH_Projects_FAILURE = 'FETCH_Projects_FAILURE';

export const fetchProjectsBegin = () => ({
    type: FETCH_Projects_BEGIN,
});

export const fetchProjectsSuccess = projects => ({
    type: FETCH_Projects_SUCCESS,
    payload: {projects},
});

export const fetchProjectsFailure = error => ({
    type: FETCH_Projects_FAILURE,
    payload: {error},
});

export const fetchProjects = () => {
    return dispatch => {
        dispatch(fetchProjectsBegin());

        request('get', '/projects').then((response) => {
            dispatch(fetchProjectsSuccess(response.data));
        }).catch(error => {
            if (!error.response) {
                dispatch(fetchProjectsFailure('Unknown error'));
                return;
            }

            dispatch(fetchProjectsFailure(error.response.data));
        });
    };
};
