import {request} from '../../api/ApiRequest';

export const FETCH_Employees_BEGIN = 'FETCH_Employees_BEGIN';
export const FETCH_Employees_SUCCESS = 'FETCH_Employees_SUCCESS';
export const FETCH_Employees_FAILURE = 'FETCH_Employees_FAILURE';

export const fetchEmployeesBegin = () => ({
    type: FETCH_Employees_BEGIN,
});

export const fetchEmployeesSuccess = employees => ({
    type: FETCH_Employees_SUCCESS,
    payload: {employees},
});

export const fetchEmployeesFailure = error => ({
    type: FETCH_Employees_FAILURE,
    payload: {error},
});

export const fetchEmployees = () => {
    return dispatch => {
        dispatch(fetchEmployeesBegin());

        request('get', '/employees').then((response) => {
            dispatch(fetchEmployeesSuccess(response.data));
        }).catch(error => {
            if (!error.response) {
                dispatch(fetchEmployeesFailure('Unknown error'));
                return;
            }

            dispatch(fetchEmployeesFailure(error.response.data));
        });
    };
};
