import {
    FETCH_TIMECARDS_BEGIN,
    FETCH_TIMECARDS_SUCCESS,
    FETCH_TIMECARDS_FAILURE
} from './TimeCardsActions';

const initialState = {
    timeCard: null,
    loading: true,
    error: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TIMECARDS_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
            };

        case FETCH_TIMECARDS_SUCCESS:
            return {
                ...state,
                loading: false,
                timeCard: action.payload.timeCards,
            };

        case FETCH_TIMECARDS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };

        default:
            return state;
    }
}
