import {
    FETCH_REPORT_BEGIN,
    FETCH_REPORT_SUCCESS,
    FETCH_REPORT_FAILURE
} from './ReportsActions';

const initialState = {
    timeCard: null,
    loading: true,
    error: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_REPORT_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
            };

        case FETCH_REPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                timeCard: action.payload.report,
            };

        case FETCH_REPORT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };

        default:
            return state;
    }
}
