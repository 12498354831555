import {request} from '../../api/ApiRequest';

export const FETCH_TIMECARD_BEGIN = 'FETCH_TIMECARD_BEGIN';
export const FETCH_TIMECARD_SUCCESS = 'FETCH_TIMECARD_SUCCESS';
export const FETCH_TIMECARD_FAILURE = 'FETCH_TIMECARD_FAILURE';

export const INIT_TIMECARD_BEGIN = 'INIT_TIMECARD_BEGIN';
export const INIT_TIMECARD_SUCCESS = 'INIT_TIMECARD_SUCCESS';
export const INIT_TIMECARD_FAILURE = 'INIT_TIMECARD_FAILURE';

export const SAVE_TIMECARD_BEGIN = 'SAVE_TIMECARD_BEGIN';
export const SAVE_TIMECARD_SUCCESS = 'SAVE_TIMECARD_SUCCESS';
export const SAVE_TIMECARD_FAILURE = 'SAVE_TIMECARD_FAILURE';

export const fetchTimeCardBegin = () => ({
    type: FETCH_TIMECARD_BEGIN,
});

export const fetchTimeCardSuccess = timeCard => ({
    type: FETCH_TIMECARD_SUCCESS,
    payload: {timeCard},
});

export const fetchTimeCardFailure = error => ({
    type: FETCH_TIMECARD_FAILURE,
    payload: {error},
});

export const initTimeCardBegin = () => ({
    type: INIT_TIMECARD_BEGIN,
});

export const initTimeCardSuccess = timeCard => ({
    type: INIT_TIMECARD_SUCCESS,
    payload: {timeCard},
});

export const initTimeCardFailure = error => ({
    type: INIT_TIMECARD_FAILURE,
    payload: {error},
});

export const saveTimeCardBegin = () => ({
    type: SAVE_TIMECARD_BEGIN,
});

export const saveTimeCardSuccess = timeCard => ({
    type: SAVE_TIMECARD_SUCCESS,
    payload: {timeCard},
});

export const saveTimeCardFailure = error => ({
    type: SAVE_TIMECARD_FAILURE,
    payload: {error},
});

export const fetchTimeCard = (projectId, weekBegin) => {
    return dispatch => {
        dispatch(fetchTimeCardBegin());

        request('get', '/time-cards/by-week/' + projectId + '/' + weekBegin).then((response) => {
            if (response.status !== 200) {
                dispatch(fetchTimeCardFailure(response.data.message));
            } else {
                dispatch(fetchTimeCardSuccess(response.data));
            }
        }).catch(error => {
            if (!error.response) {
                dispatch(fetchTimeCardFailure('Unknown error'));
                return;
            }

            dispatch(fetchTimeCardFailure(error.response.data.message));
        });
    };
};

export const updateTimeCardApproval = (projectId, weekBegin, timeCard) => {
    return dispatch => {
        dispatch(saveTimeCardBegin());

        let url = '/time-cards/by-week/' + projectId + '/' + weekBegin;

        request('put', url, timeCard).then((response) => {
            if (response.status === 204) {
                dispatch(saveTimeCardSuccess(timeCard));
            } else {
                dispatch(saveTimeCardFailure('Unknown error'));
            }
        }).catch(error => {
            if (!error.response) {
                dispatch(saveTimeCardFailure('Unknown error'));
                return;
            }

            dispatch(saveTimeCardFailure(error.response.data));
        });
    };
};

export const saveTimeCard = (projectId, weekBegin, values) => {
    return request('put', '/time-cards/by-week/' + projectId + '/' + weekBegin, values)
        .catch(error => {
            console.debug('error', error);
        });
};

export const initTimeCard = (projectId, weekBegin) => {
    return dispatch => {
        dispatch(initTimeCardBegin());

        let timeCard = {
            weekBegin: weekBegin,
            projectId: projectId,
        };

        request('put', '/time-cards/by-week/' + projectId + '/' + weekBegin, timeCard).then((response) => {
            if (response.status === 204) {
                dispatch(initTimeCardSuccess(timeCard));
            } else {
                dispatch(initTimeCardFailure('Unknown error'));
            }
        }).catch(error => {
            if (!error.response) {
                dispatch(initTimeCardFailure('Unknown error'));
                return;
            }

            dispatch(initTimeCardFailure(error.response.data));
        });
    };
};

