import React from 'react';
import AddTimeCardForm from './../../component/form/AddTimeCardForm';
import {connect} from "react-redux";
import {fetchProjects} from "../../redux/projects/ProjectActions";
import {fetchEmployees} from "../../redux/employees/EmployeeActions";
import {Redirect} from 'react-router'

class AddTimeCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            role: props.role,
            toDashboard: false,
            checkForTimeCard: false,
        };
    }

    handleSubmit = values => {
        //console.debug('handleSubmit');
    };

    render() {
        if (this.state.toDashboard === true) {
            return <Redirect to='/'/>

        } else {
            return <div>
                <AddTimeCardForm
                    history={this.props.history}
                    enableReinitialize={true}
                    onSubmit={this.handleSubmit}
                />
            </div>
        }
    };

    componentDidMount() {
        const {dispatch} = this.props;

        dispatch(fetchProjects());
        dispatch(fetchEmployees());
    }
}

export default connect()(AddTimeCard);
