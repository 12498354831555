import React from 'react';
import {Typography} from '@material-ui/core';
import {Field, formValueSelector, reduxForm, SubmissionError} from 'redux-form'
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import withStyles from "@material-ui/core/styles/withStyles";
import Divider from '@material-ui/core/Divider/Divider';
import Card from '@material-ui/core/Card/Card';
import CardContent from '@material-ui/core/CardContent/CardContent';
import Button from '@material-ui/core/Button/Button';
import MenuItem from '@material-ui/core/MenuItem';
import {DatePicker} from "material-ui-pickers";
import FormControl from '@material-ui/core/FormControl';
import {initTimeCard} from "./../../redux/time-card/TimeCardActions";
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
    button: {
        color: "white!important",
        background: "#101ba5!important",
        borderRadius: "25px!important",
        padding: "6px 20px!important",
    },
    formControl: {
        margin: theme.spacing.unit,
        fullWidth: true,
        display: 'flex',
        wrap: 'nowrap',
        minWidth: 120
    },
    required: {
        fontFamily: 'Roboto, Helvetica, Arial sans-serif !important',
        color: '#c00',
        lineHeight: '2rem'
    },
    select: {
        minHeight: 50,
    },
});

class AddTimeCardForm extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        dispatch: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            toTimeCard: false,
            saveTimeCard: false,
            labelWidth: 0,
        };
    }

    disableDays = (date) => {
        const {projects, hasProjectId} = this.props;
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        let project = false;

        if (undefined !== hasProjectId) {
            project = projects.find(x => x.id === hasProjectId);
        }

        let dayNumber = days.indexOf(project.weekending);

        if (-1 === dayNumber) {
            return true;
        }

        let startingDay = (dayNumber + 1) % 7;

        return startingDay !== date.day();
    };

    renderDateField = ({input, label, meta: {touched, error}, children, ...custom}) => {
        return <div>
            <DatePicker
                {...input}
                label={label}
                placeholder={label}
                {...custom}
                fullWidth
                value={input.value !== '' ? new Date(input.value) : null}
                variant="outlined"
                shouldDisableDate={this.disableDays}
                autoOk={true}
            />
            {touched && error && <span className={custom.classes.required}>{error}</span>}
        </div>
    };

    renderSelectField = ({input, label, meta: {touched, error}, children, ...custom}) => (
        <div>
            <TextField
                {...input}
                select
                label={label}
                placeholder={label}
                children={children}
                {...custom}
                fullWidth
            />
            {touched && error && <span>{error}</span>}
        </div>
    );

    validate = values => {
        if (undefined === values.weekBegin) {
            throw new SubmissionError({
                weekBegin: 'Week Beginning is required.',
                _error: 'Missing Required!'
            });
        } else if (undefined === values.projectId) {
            throw new SubmissionError({
                projectId: 'Project is required.',
                _error: 'Missing Required!'
            });
        } else if (undefined !== values.weekBegin && undefined !== values.projectId) {
            this.setState({
                saveTimeCard: true,
                projectId: values.projectId,
                weekBegin: values.weekBegin,
            });
        }
    };

    render() {
        const {classes, timeCard, handleSubmit, projects, hasProjectId} = this.props;

        return (
            <Card>
                <CardContent>
                    <Typography variant="h4" gutterBottom component="h2">Time Card</Typography>

                    <Divider/>

                    {null !== timeCard && (
                        <form name="addTimeCard" onSubmit={handleSubmit(this.validate)}>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <Field
                                    label="Project"
                                    placeholder="Project"
                                    name="projectId"
                                    component={this.renderSelectField}
                                    fullWidth
                                    className={classes.select}
                                    variant="outlined"
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {null !== projects ? projects.map((project) => {
                                        return <MenuItem value={project.id}
                                                         key={project.id}>{project.text}</MenuItem>
                                    }) : ''}
                                </Field>
                            </FormControl>

                            {hasProjectId &&
                            <FormControl variant="outlined" className={classes.formControl}>
                                <Field
                                    label="Week Beginning"
                                    name="weekBegin"
                                    component={this.renderDateField}
                                    fullWidth
                                    variant="outlined"
                                    classes={classes}
                                >
                                </Field>
                            </FormControl>
                            }

                            <div className="form-group">
                                <Button type="submit" className={classes.button}>Add Time Card</Button>
                            </div>
                        </form>
                    )}
                </CardContent>
            </Card>
        );
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {dispatch, redirectToEdit, history} = this.props;
        const {projectId, weekBegin, saveTimeCard} = this.state;

        if (saveTimeCard && saveTimeCard !== prevState.saveTimeCard) {
            this.setState({
                saveTimeCard: false,
                toTimeCard: "/" + projectId + "/" + weekBegin.format('YYYY-MM-DD'),
            });

            dispatch(initTimeCard(projectId, weekBegin.format('YYYY-MM-DD')));
        }

        if (redirectToEdit && projectId !== undefined && weekBegin !== undefined) {
            history.push(`/time-card/${projectId}/${weekBegin.format('YYYY-MM-DD')}`);
        }
    };
}

let InitializeFromStateForm = reduxForm({
    form: 'addTimeCard'
})(AddTimeCardForm);

const mapStateToProps = function (state) {
    const selector = formValueSelector('addTimeCard');
    const hasProjectId = selector(state, 'projectId');

    return ({
        hasProjectId: hasProjectId,
        projects: state.projects.items,
        redirectToEdit: state.timeCard.redirectToEdit,
    })
};

export default connect(mapStateToProps)(withStyles(styles)(InitializeFromStateForm));
