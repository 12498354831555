import {
    FETCH_TIMECARD_BEGIN,
    FETCH_TIMECARD_FAILURE,
    FETCH_TIMECARD_SUCCESS,
    INIT_TIMECARD_BEGIN,
    INIT_TIMECARD_FAILURE,
    INIT_TIMECARD_SUCCESS
} from './TimeCardActions';

const initialState = {
    timeCard: null,
    loading: true,
    editing: false,
    redirectToEdit: false,
    error: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TIMECARD_BEGIN:
            return {
                ...state,
                loading: true,
                editing: false,
                error: null,
            };

        case FETCH_TIMECARD_SUCCESS:
            return {
                ...state,
                loading: false,
                editing: false,
                timeCard: action.payload.timeCard,
            };

        case FETCH_TIMECARD_FAILURE:
            return {
                ...state,
                loading: false,
                editing: false,
                error: action.payload.error,
            };

        case INIT_TIMECARD_BEGIN:
            return {
                ...state,
                error: null,
                redirectToEdit: false,
            };

        case INIT_TIMECARD_FAILURE:
            return {
                ...state,
                error: action.payload.error,
                redirectToEdit: false,
            };

        case INIT_TIMECARD_SUCCESS:
            return {
                ...state,
                redirectToEdit: true,
            };

        default:
            return state;
    }
}
