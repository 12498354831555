import React, {Component} from 'react';
import FullPageLoading from '../component/FullPageLoading';
import userManager, {authority, clientId} from '../utils/userManager';

class Signout extends Component {
    render() {
        return <FullPageLoading/>;
    }

    componentDidMount() {
        const logoutUrl = new URL(`${authority}/v2/logout?client_id=${clientId}`);
        logoutUrl.searchParams.set('returnTo', `${window.origin}/signout-callback`);
        window.location= logoutUrl;
    }
}

export default Signout;
