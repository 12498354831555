import axios from 'axios';
import store from '../store';

export const request = (method, path, data, cancelToken, requestBlob = false) => {
    const token = store.getState().oidc.user ? store.getState().oidc.user.access_token : '';

    let url = '';

    if (path.startsWith('http:') || path.startsWith('https:')) {
        url = path;
    } else {
        url = process.env.REACT_APP_API_URL + path
    }

    const config = {
        method,
        url,
        headers: {
            'Authorization': 'Bearer ' + token,
        },
    };

    if (cancelToken) {
        config.cancelToken = cancelToken;
    }

    if (requestBlob) {
        config.responseType = 'blob';
    }

    if (data) {
        if (method === 'get') {
            config.params = data;
        } else {
            config.data = data;
        }
    }

    return axios.request(config);
};
