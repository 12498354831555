import Button from '@material-ui/core/Button/Button';
import React from 'react';
import EditTimeCardForm from './../../component/form/EditTimeCardForm';
import {connect} from "react-redux";
import {Typography} from '@material-ui/core';
import Card from '@material-ui/core/Card/Card';
import CardContent from '@material-ui/core/CardContent/CardContent';
import Paper from '@material-ui/core/Paper/Paper';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table/Table';
import TableBody from '@material-ui/core/TableBody/TableBody';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableHead from '@material-ui/core/TableHead/TableHead';
import TableFooter from '@material-ui/core/TableFooter/TableFooter';
import TableRow from '@material-ui/core/TableRow/TableRow';
import withStyles from "@material-ui/core/styles/withStyles";
import {fetchTimeCard, updateTimeCardApproval} from "../../redux/time-card/TimeCardActions";
import {fetchProjects} from "../../redux/projects/ProjectActions";
import {Redirect} from 'react-router'
import ConfirmationDialog from './../../component/Dialog/ConfirmationDialog';
import RejectDialog from './../../component/Dialog/RejectDialog';
import moment from 'moment';
import {saveTimeCard} from '../../redux/time-card/TimeCardActions';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUtensils} from '@fortawesome/free-solid-svg-icons'

const styles = theme => ({
    button: {
        color: "white!important",
        background: "#101ba5!important",
        borderRadius: "25px!important",
        padding: "6px 20px!important",
    },
    buttonGroup: {
        textAlign: 'center',
    },
    card: {
        marginBottom: theme.spacing.unit * 2,
    },
    formCell: {
        display: 'inline-block',
        paddingLeft: 6,
        verticalAlign: 'top'
    },
    formLabel: {
        display: 'inline-block',
        fontSize: '.8rem!important',
        fontWeight: 'normal',
        textTransform: 'uppercase',
        verticalAlign: 'top',
        paddingTop: 5
    },
    notes: {
        paddingLeft: 6,
        verticalAlign: 'top'
    },
    paper: {
        width: '100%',
        minWidth: 500,
        marginTop: 10,
    },
    table: {
        minWidth: 700,
    },
    formColumn: {
        minWidth: 400,
        display: 'inline-block'
    },
    tableWrapper: {
        overflowX: 'auto',
        marginBottom: 15,
    },
    tableCell: {
        paddingTop: 4,
        paddingRight: 24,
        whiteSpace: 'nowrap',
    },
});

class Detail extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        dispatch: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired,
        editing: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            action: false,
            toDashboard: false,
            timeCard: this.props.timeCard,
            editing: this.props.editing,
            refetch: false,
        };
    }

    getProjectName = value => {
        const {projects} = this.props;
        let text = 'unknown';

        if (null !== projects) {
            let project = projects.find(c => c.id === parseInt(value));
            text = undefined === project ? text : project.text;
        }

        return text;
    };

    handleSubmit = () => {
        this.setState({action: 'submit'});
    };

    formatSaveDate = (value) => {
        if ('' === value) {
            return value;
        }

        let date = moment.utc(value, ['HH:mm a', 'YYYY-MM-DD[T]HH:mm:ss']);

        if (date.isValid()) {
            return date.format('YYYY-MM-DD[T]HH:mm:ss-07:00');
        }

        return '';
    };

    formatTime = (value) => {
        if ('' === value) {
            return value;
        }

        let date = moment.utc(value, ['HH:mm a', 'YYYY-MM-DD[T]HH:mm:ss']);

        if (date.isValid()) {
            return date.format('hh:mm A');
        }

        return '';
    };

    saveForm = (values) => {
        const {timeCard} = this.props;

        let weekBegin = moment(timeCard.weekBegin, 'MM/DD/YYYY').format('YYYY-MM-DD');
        let days = ["day1", "day2", "day3", "day4", "day5", "day6", "day7"];
        let fields = ["time-in", "time-out", "time-in-2", "time-out-2", "time-in-3", "time-out-3"];

        days.forEach((dayName) => {
            fields.forEach((fieldName) => {
                let timeValue = values[dayName][fieldName + '-display'];

                if (undefined !== timeValue && '' !== timeValue) {
                    values[dayName][fieldName] = this.formatSaveDate(this.filterTimeString(timeValue));
                    values[dayName][fieldName + '-display'] = this.formatTime(this.filterTimeString(timeValue));
                } else {
                    values[dayName][fieldName] = '';
                }
            });
        });

        return saveTimeCard(timeCard.projectId, weekBegin, values).then(resp => {
            return true;
        });
    };

    handleAction = value => event => {
        this.setState({action: value});
    };

    handleRejectReason = value => {
        const {timeCard} = this.props;
        timeCard.rejectionReason = value;
        this.setState({action: 'reject'});
    };

    handleEdit = value => event => {
        const {timeCard} = this.props;

        if (false === value && undefined === timeCard.timeCardId) {
            this.setState({
                toDashboard: true
            });
        }

        this.setState({
            editing: value
        });
    };

    handleBrowse = value => event => {
        const {timeCard} = this.props;

        if (false === value && undefined === timeCard.timeCardId) {
            this.setState({
                toDashboard: true
            });
        }

        this.setState({
            editing: value,
            refetch: true,
        });
    };

    filterTimeString = (timeString) => {
        if (undefined === timeString) {
            return '';
        }

        /**
         * Filter rules:
         *  If there are no digits, don't filter.
         *  If there is no colon add one.
         *  If there is no [aApP] and there is no leading zero, assume times less than workdayStartHour are PM.
         *
         * @todo the workdayStartHour needs to be user definable (from the model or local storage)
         */
        if (moment.isMoment(timeString)) {
            timeString = timeString.format('HH:mm a');
        }

        if (timeString.search(/\d/) < 0) {
            return timeString;
        }

        let workdayStartHour = 7;
        let colonPosition = timeString.search(':');

        if (colonPosition < 0 || isNaN(timeString)) {
            // no colon or has colon but no am/pm
            let pattern = /^(.*\d)(\d\d)(.*)$/;
            let returnString = timeString.replace(pattern, '$1:$2$3');
            let pieces = returnString.split(':');

            if (returnString.search(/[aApP]/) < 0 && pieces[0].search('0') < 0) {
                let hour = parseInt(pieces[0], 10); // make sure it's a base 10 int.

                if (10 > hour) {
                    returnString = '0' + returnString;
                }

                if (hour < workdayStartHour) {
                    return returnString + 'p';
                } else {
                    return returnString;
                }
            } else {
                return returnString;
            }
        } else {
            return timeString;
        }
    }

    render() {
        const {classes, loading, timeCard, client} = this.props;
        const {projectId, weekBegin, employeeId, editing} = this.state;
        const columnNames = [
            "dayname",
            "date",
            "time-in",
            "time-out",
            "time-in-2",
            "time-out-2",
            "time-in-3",
            "time-out-3",
            "day-type",
            "meal-penalty",
            "comment",
            "work-hours",
            "total-standard-time-hours",
            "total-overtime-hours",
            "total-double-time-hours"
        ];
        const labels = {
            "dayname": "",
            "date": "Date",
            "time-in": "Time In",
            "time-out": "Time Out",
            "time-in-2": "Time In",
            "time-out-2": "Time Out",
            "time-in-3": "Time In",
            "time-out-3": "Time Out",
            "day-type": "Day Type",
            "comment": "Comments",
            "work-hours": "Work Hours",
            "meal-penalty": "",
            "total-standard-time-hours": "Standard Time Hours",
            "total-overtime-hours": "Overtime Hours",
            "total-double-time-hours": "Double Time Hours",
        };

        if (this.state.toDashboard === true) {
            return <Redirect to='/'/>
        } else if (null === timeCard || loading) {
            return <div>Loading...</div>
        } else if (editing) {
            if (undefined === timeCard.employeeId && undefined !== employeeId) {
                timeCard.employeeId = employeeId;
            }

            return <div>
                <EditTimeCardForm
                    handleEdit={this.handleEdit}
                    handleBrowse={this.handleBrowse}
                    enableReinitialize={true}
                    timeCard={timeCard}
                    onSubmit={this.saveForm}
                />
            </div>
        } else if (null === timeCard) {
            return <div>
                <div>A time card could not be found with the current search parameters.</div>
            </div>
        } else if (undefined === client) {
            return <div>
                <div>Role is required.</div>
            </div>
        } else {
            let days = ["day1", "day2", "day3", "day4", "day5", "day6", "day7"];
            let dayList = days.map(function (dayName, rowIndex) {
                return <TableRow key={dayName} hover={true}>
                    {columnNames.map((fieldName, index) => (
                        <TableCell key={fieldName} className={classes.tableCell}>
                            {(() => {
                                if (0 === index) {
                                    return moment(weekBegin, 'YYYY-MM-DD').add((rowIndex), 'days').format('dddd');
                                } else if (1 === index) {
                                    return moment(weekBegin, 'YYYY-MM-DD').add((rowIndex), 'days').format('MM/DD/YYYY');
                                } else if (fieldName.includes('time-in') || fieldName.includes('time-out')) {
                                    if ('' !== timeCard[dayName][fieldName]) {
                                        return moment.utc(timeCard[dayName][fieldName]).utcOffset('-0700').format('LT');
                                    }

                                    return '';
                                } else if ('meal-penalty' === fieldName) {
                                    if (timeCard[dayName][fieldName]) {
                                        return <FontAwesomeIcon title="Meal Penalty" icon={faUtensils}/>;
                                    }

                                    return '';
                                } else {
                                    return timeCard[dayName][fieldName];
                                }
                            })()}
                        </TableCell>
                    ))}
                </TableRow>
            });
            return (
                <Card>
                    <CardContent>
                        <Typography variant="h4" gutterBottom component="h2">
                            Time Card Browse
                        </Typography>

                        <div className={classes.formColumn}>
                            <Typography variant="h6" className={classes.formLabel}>
                                Week Begin:
                            </Typography>
                            <Typography variant="subtitle1" className={classes.formCell}>
                                {weekBegin}
                            </Typography>
                            <br/>
                            <Typography variant="h6" className={classes.formLabel}>
                                Approving Manager:
                            </Typography>
                            <Typography variant="subtitle1" className={classes.formCell}>
                                {timeCard.managerName}
                            </Typography>
                        </div>
                        <div className={classes.formColumn}>
                            <Typography variant="h6" className={classes.formLabel}>
                                Project ID:
                            </Typography>
                            <Typography variant="subtitle1" className={classes.formCell}>
                                {this.getProjectName(projectId)}
                            </Typography>
                            <br/>
                            <Typography variant="h6" className={classes.formLabel}>
                                Co. & Assignment:
                            </Typography>
                            <Typography variant="subtitle1" className={classes.formCell}>
                                {timeCard.projectDisplayName}
                            </Typography>
                        </div>

                        <Paper className={classes.paper}>
                            <div className={classes.tableWrapper}>
                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            {columnNames.map(fieldName => (
                                                <TableCell key={fieldName}>
                                                    {labels[fieldName]}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {dayList}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>

                                            {["total-hours", "total-standard-time-hours", "total-overtime-hours",
                                                "total-double-time-hours"].map(fieldName => (
                                                <TableCell key={fieldName}>
                                                    {'' !== timeCard[fieldName] ? timeCard[fieldName] : 0}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </div>
                        </Paper>

                        <div className={classes.formColumn}>
                            <Typography variant="h6" className={classes.formLabel}>
                                Submitted?
                            </Typography>
                            <Typography variant="subtitle1" className={classes.formCell}>
                                {timeCard.submitted}
                            </Typography>
                        </div>

                        <div className={classes.formColumn}>
                            <Typography variant="h6" className={classes.formLabel}>
                                Approved?
                            </Typography>
                            <Typography variant="subtitle1" className={classes.formCell}>
                                {timeCard.approved}
                            </Typography>
                        </div>

                        <div className={classes.formColumn}>
                            <Typography variant="h6" className={classes.formLabel}>
                                Payroll Locked?
                            </Typography>
                            <Typography variant="subtitle1" className={classes.formCell}>
                                {timeCard.locked}
                            </Typography>
                        </div>

                        <div className={classes.formColumn}>
                            <Typography variant="h6" className={classes.formLabel}>
                                Record Creator:
                            </Typography>
                            <Typography variant="subtitle1" className={classes.formCell}>
                                {timeCard.creator}
                            </Typography>
                        </div>

                        <div className={classes.formColumn}>
                            <Typography variant="h6" className={classes.formLabel}>
                                Date Created:
                            </Typography>
                            <Typography variant="subtitle1" className={classes.formCell}>
                                {timeCard.dateCreated}
                            </Typography>
                        </div>

                        <div className={classes.formColumn}>
                            <Typography variant="h6" className={classes.formLabel}>
                                Modifier:
                            </Typography>
                            <Typography variant="subtitle1" className={classes.formCell}>
                                {timeCard.modifier}
                            </Typography>
                        </div>

                        <div className={classes.formColumn}>
                            <Typography variant="h6" className={classes.formLabel}>
                                Date Modified:
                            </Typography>
                            <Typography variant="subtitle1" className={classes.formCell}>
                                {timeCard.dateModified}
                            </Typography>
                        </div>

                        <div className={classes.formColumn}>
                            <Typography variant="h6" className={classes.formLabel}>
                                Notes:
                            </Typography>
                            {timeCard.notes.split('\r').map((item, i) => {
                                return <Typography variant="subtitle1" className={classes.notes} key={i}>{item}</Typography>;
                            })}
                        </div>

                        <div className={classes.buttonGroup}>
                            {'Yes' !== timeCard.submitted && client.role !== 'Manager' &&
                            <Button type="submit" className={classes.button}
                                    onClick={this.handleEdit(true)}>Edit</Button>}

                            {'Yes' !== timeCard.submitted && client.role !== 'Manager' &&
                            <ConfirmationDialog handleSubmit={this.handleSubmit}/>}

                            {client.role === 'Manager' && 'Yes' === timeCard.submitted && 'Not Yet' === timeCard.approved &&
                            <Button type="submit" className={classes.button}
                                    onClick={this.handleAction('approve')}>Approve</Button>}

                            {client.role === 'Manager' && 'Yes' === timeCard.submitted && 'Not Yet' === timeCard.approved &&
                            <RejectDialog handleRejectReason={this.handleRejectReason} handleSubmit={this.handleSubmit}/>}

                            {client.role === 'Manager' && 'Yes' === timeCard.submitted && 'Rejected' === timeCard.approved &&
                            <Button type="submit" className={classes.button}
                                    onClick={this.handleAction('unreject')}>UnReject</Button>}
                        </div>
                    </CardContent>
                </Card>
            );
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {dispatch, timeCard} = this.props;
        const {action} = this.state;

        if (false !== action) {
            if ('approve' === action) {
                timeCard.approved = 'Yes';
            } else if ('reject' === action) {
                timeCard.approved = 'Rejected';
                timeCard.submitted = 'No';
            } else if ('submit' === action) {
                timeCard.submitted = 'Yes';
                timeCard.approved = 'Not Yet';
            } else if ('unreject' === action) {
                timeCard.approved = 'Not Yet';
            }

            dispatch(updateTimeCardApproval(
                timeCard.projectId,
                moment(timeCard.weekBegin, 'MM/DD/YYYY').format('YYYY-MM-DD'),
                timeCard
            ));

            this.setState({
                action: false,
            });
        }

        if (this.props.match.params.projectId !== prevProps.match.params.projectId ||
            this.props.match.params.weekBegin !== prevProps.match.params.weekBegin ||
            this.state.refetch) {

            this.setState({
                refetch: false,
            });

            dispatch(fetchTimeCard(
                this.props.match.params.projectId,
                this.props.match.params.weekBegin
            ));
        }

        if (null !== timeCard && undefined === timeCard.timeCardId && this.state.editing !== this.props.editing) {
            this.setState({
                editing: this.props.editing,
            });
        }
    }

    componentDidMount() {
        const {dispatch} = this.props;

        this.setState({
            timeCard: null,
            loading: true,
        });

        dispatch(fetchProjects());

        if (this.state.projectId !== this.props.match.params.projectId ||
            this.state.weekBegin !== this.props.match.params.weekBegin) {
            this.setState({
                projectId: this.props.match.params.projectId,
                weekBegin: this.props.match.params.weekBegin
            })
        }

        dispatch(fetchTimeCard(
            this.props.match.params.projectId,
            this.props.match.params.weekBegin
        ));
    }
}

const mapStateToProps = function (state) {
    return ({
        client: state.client.client,
        projects: state.projects.items,
        initialValues: state.timeCard.timeCard,
        timeCard: state.timeCard.timeCard,
        editing: state.timeCard.editing,
        loading: state.timeCard.loading,
        error: state.timeCard.error,
    })
};

export default connect(mapStateToProps)(withStyles(styles)(Detail));
