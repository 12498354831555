import React from 'react';
import {Typography} from '@material-ui/core';
import {Field, FieldArray, reduxForm} from 'redux-form'
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import withStyles from "@material-ui/core/styles/withStyles";
import Divider from '@material-ui/core/Divider/Divider';
import Card from '@material-ui/core/Card/Card';
import CardContent from '@material-ui/core/CardContent/CardContent';
import Button from '@material-ui/core/Button/Button';
import Table from '@material-ui/core/Table/Table';
import TableBody from '@material-ui/core/TableBody/TableBody';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableHead from '@material-ui/core/TableHead/TableHead';
import TableRow from '@material-ui/core/TableRow/TableRow';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField'
import FormHelperText from '@material-ui/core/FormHelperText';
import {DatePicker} from "material-ui-pickers";
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper/Paper';
import {Redirect} from 'react-router'
import moment from 'moment';

const styles = theme => ({
    button: {
        color: "white!important",
        background: "#101ba5!important",
        borderRadius: "25px!important",
        padding: "6px 20px!important",
    },
    buttonGroup: {
        textAlign: 'center',
    },
    formControl: {
        margin: theme.spacing.unit,
        fullWidth: true,
        display: 'flex',
        wrap: 'nowrap',
        minWidth: 120
    },
    paper: {
        width: '100%',
        minWidth: 500,
        marginTop: 10,
    },
    select: {
        minHeight: 50,
    },
    textField: {
        width: 100,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
});

class EditTimeCardForm extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        dispatch: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            labelWidth: 0,
            toTimeCard: false,
        };
    }

    disableDays = (date) => {
        const {projects, hasProjectId} = this.props;
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

        let project = false;

        if (undefined !== hasProjectId) {
            project = projects.find(x => x.id === hasProjectId);
        }

        const dow = date.day();
        const dayName = days[dow];
        return dayName !== project.weekending;
    };

    renderDateField = ({
                           input,
                           label,
                           meta: {touched, error},
                           children,
                           ...custom
                       }) => (
        <div>
            <DatePicker
                {...input}
                label={label}
                placeholder={label}
                {...custom}
                fullWidth
                value={input.value !== '' ? new Date(input.value) : null}
                variant="outlined"
                shouldDisableDate={this.disableDays}
                autoOk={true}
            />
            {touched && error && <span>{error}</span>}
        </div>
    );

    renderTextField = ({
                           input,
                           label,
                           meta: {touched, error},
                           ...custom
                       }) => {
        const {handleSubmit} = this.props;
        return <div>
            <TextField
                type="text"
                label={label}
                placeholder={label}
                {...input}
                {...custom}
                error={(undefined !== error)}
                onBlur={handleSubmit}
            />
            {(undefined !== error) ? <FormHelperText>{error}</FormHelperText> : ''}
        </div>;
    };

    renderSelectField = ({
                             input,
                             label,
                             meta: {touched, error},
                             children,
                             ...custom
                         }) => {
        const {handleSubmit} = this.props;
        return <div>
            <TextField
                {...input}
                select
                label={label}
                placeholder={label}
                children={children}
                {...custom}
                fullWidth
                onBlur={handleSubmit}
            />
            {touched && error && <span>{error}</span>}
        </div>
    };

    renderTimeField = ({
                           input,
                           label,
                           meta: {touched, error},
                           children,
                           ...custom
                       }) => {
        const {classes, handleSubmit} = this.props;

        return <div>
            <TextField
                {...input}
                label={label}
                {...custom}
                type="text"
                className={classes.textField}
                InputLabelProps={{
                    shrink: true,
                }}
                onBlur={handleSubmit}
            />
            {touched && error && <span>{error}</span>}
        </div>
    };

    renderRow = (dayName, name, cellIndex) => (
        <TableCell key={`${dayName}-${cellIndex}`}>
            {(() => {
                if (-1 !== name.indexOf('time')) {
                    return <Field
                        name={`${dayName}['${name}-display']`}
                        component={this.renderTimeField}
                    />;
                } else if ('day-type' === name) {
                    return <Field
                        name={`${dayName}['${name}']`}
                        component={this.renderSelectField}
                    >
                        <MenuItem value={''}>None</MenuItem>
                        <MenuItem value={'Work Day'}>Work Day</MenuItem>
                        <MenuItem value={'Holiday'}>Holiday</MenuItem>
                        <MenuItem value={'Vacation'}>Vacation</MenuItem>
                        <MenuItem value={'Shutdown'}>Shutdown</MenuItem>
                    </Field>
                } else if ('comment' === name) {
                    return <Field
                        name={`${dayName}['${name}']`}
                        component={this.renderTextField}
                    />;
                }
            })()}
        </TableCell>
    );

    renderTable = ({weekBegin, labels, editColumnNames}) => {
        let days = ["day1", "day2", "day3", "day4", "day5", "day6", "day7"];
        let dayList = days.map((dayName, rowIndex) => {
            return <TableRow key={dayName} hover={true}>
                <TableCell key="dayName">
                    {moment(weekBegin, 'MM/DD/YYYY').add((rowIndex), 'days').format('dddd')}
                </TableCell>
                {editColumnNames.map((name, index) => {
                    return this.renderRow(dayName, name, index);
                })}
            </TableRow>
        });

        return (
            <Table className="">
                <TableHead>
                    <TableRow>
                        <TableCell key={0}/>
                        {editColumnNames.map(fieldName => (
                            <TableCell key={fieldName}>
                                {labels[fieldName]}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dayList}
                </TableBody>
            </Table>
        )
    };

    render() {
        const {classes, loading, timeCard, projects, handleBrowse} = this.props;
        const editColumnNames = [
            "time-in",
            "time-out",
            "time-in-2",
            "time-out-2",
            "time-in-3",
            "time-out-3",
            "day-type",
            "comment"
        ];
        const labels = {
            "dayname": "",
            "date": "Date",
            "time-in": "Time In",
            "time-out": "Time Out",
            "time-in-2": "Time In",
            "time-out-2": "Time Out",
            "time-in-3": "Time In",
            "time-out-3": "Time Out",
            "day-type": "Day Type",
            "comment": "Comments",
            "work-hours": "Work Hours",
            "total-standard-time-hours": "Standard Time Hours",
            "total-overtime-hours": "Overtime Hours",
            "total-double-time-hours": "Double Time Hours",
        };

        if (this.state.toTimeCard !== false) {
            let redirectTo = this.state.toTimeCard;

            this.setState({
                "toTimeCard": false,
            });

            return <Redirect to={"/time-card" + redirectTo}/>
        } else {
            return (
                <Card>
                    <CardContent>
                        <Typography variant="h4" gutterBottom component="h2">
                            Time Card
                        </Typography>

                        <Divider/>

                        {null !== timeCard && (
                            <form name="editTimeCardForm" onSubmit={handleBrowse}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <Field
                                        disabled
                                        label="Week Beginning"
                                        name="weekBegin"
                                        component={this.renderDateField}
                                        fullWidth
                                        variant="outlined"
                                    >
                                    </Field>
                                </FormControl>

                                <FormControl variant="outlined" className={classes.formControl}>
                                    <Field
                                        disabled
                                        label="Project"
                                        placeholder="Project"
                                        name="projectId"
                                        component={this.renderSelectField}
                                        fullWidth
                                        className={classes.select}
                                        variant="outlined"
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {null !== projects ? projects.map((project) => {
                                            return <MenuItem value={project.id}
                                                             key={project.id}>{project.text}</MenuItem>
                                        }) : ''}
                                    </Field>
                                </FormControl>

                                <div className={classes.buttonGroup}>
                                    <Paper className={classes.paper}>
                                        <div className={classes.tableWrapper}>
                                            <FieldArray loading={loading} timeCard={timeCard}
                                                        labels={labels} editColumnNames={editColumnNames} name="days"
                                                        weekBegin={timeCard.weekBegin}
                                                        component={this.renderTable}/>
                                        </div>
                                    </Paper>

                                    <div className="form-group">
                                        <Button type="submit" className={classes.button}
                                                onClick={handleBrowse(false)}>Done</Button>
                                    </div>
                                </div>
                            </form>
                        )}
                    </CardContent>
                </Card>
            );
        }
    }
}

let InitializeFromStateForm = reduxForm({
    form: 'editTimeCardForm',
})(EditTimeCardForm);

const mapStateToProps = function (state) {
    let timeCard = state.timeCard.timeCard;
    let days = ["day1", "day2", "day3", "day4", "day5", "day6", "day7"];
    let fields = ["time-in", "time-out", "time-in-2", "time-out-2", "time-in-3", "time-out-3"];

    days.forEach((dayName) => {
        fields.forEach((fieldName) => {
            const date = moment(timeCard[dayName][fieldName], moment.ISO_8601, true);

            if ('' !== timeCard[dayName][fieldName] && date.isValid()) {
                timeCard[dayName][fieldName+'-display'] = date.utcOffset('-0700').format('hh:mm A');
            }
        });
    });

    return ({
        projects: state.projects.items,
        timeCard: timeCard,
        initialValues: state.timeCard.timeCard,
        loading: state.timeCard.loading,
        error: state.timeCard.error,
    })
};

export default connect(mapStateToProps)(withStyles(styles)(InitializeFromStateForm));
