import {InMemoryWebStorage, WebStorageStateStore} from 'oidc-client';
import {createUserManager} from 'redux-oidc';

export const clientId = process.env.REACT_APP_OAUTH2_CLIENT_ID;
export const authority = process.env.REACT_APP_OAUTH2_AUTHORITY;

const userManagerConfig = {
    client_id: clientId,
    redirect_uri: `${window.origin}/callback`,
    response_type: 'code',
    scope: 'openid profile email',
    authority: authority,
    silent_redirect_uri: `${window.origin}/silent-renew`,
    post_logout_redirect_uri: `${window.origin}/signout-callback`,
    automaticSilentRenew: true,
    filterProtocolClaims: true,
    loadUserInfo: false,
    userStore: new WebStorageStateStore({store: new InMemoryWebStorage()}),
    extraQueryParams: {
        audience: 'https://atretime.api',
    },
};

export default createUserManager(userManagerConfig);

