import axios from 'axios';
import {request} from '../../api/ApiRequest';
export const FETCH_DATA_BEGIN = 'FETCH_DATA_BEGIN';
export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS';
export const FETCH_DATA_FAILURE = 'FETCH_DATA_FAILURE';
export const UPDATE_PER_PAGE = 'UPDATE_PER_PAGE';
export const UPDATE_SORT = 'UPDATE_SORT';
export const RESET_DATA = 'RESET_DATA';

const fetchDataBegin = () => ({
    type: FETCH_DATA_BEGIN,
});

const fetchDataSuccess = (data) => ({
    type: FETCH_DATA_SUCCESS,
    payload: {data},
});

const fetchDataFailure = (error) => ({
    type: FETCH_DATA_FAILURE,
    payload: {error},
});

const runUpdateSort = (orderBy, order) => ({
    type: UPDATE_SORT,
    payload: {orderBy, order},
});

let source;

const fetchDataByUrl = (dispatch, url) => {
    if (source) {
        source.cancel();
    }

    source = axios.CancelToken.source();
    dispatch(fetchDataBegin());

    request('get', url, null, source.token).then((response) => {
        dispatch(fetchDataSuccess(response.data));
    }).catch((error) => {
        if (axios.isCancel(error)) {
            return;
        }

        if (!error.response) {
            dispatch(fetchDataFailure('Unknown error'));
            return;
        }

        dispatch(fetchDataFailure(error.response.data));
    });
};

export const updateSort = (orderBy, order, data) => {
    return dispatch => {
        dispatch(runUpdateSort(orderBy, order));
    }
};

export const sortBy = (field, reverse, primer) => {
    let key = primer ?
        function (x) {
            return primer(x[field])
        } :
        function (x) {
            return x[field]
        };

    reverse = !reverse ? 1 : -1;

    return function (a, b) {
        let a1 = key(a);
        let b2 = key(b);

        return reverse * ((a1 > b2) - (b2 > a1));
    }
};

export const fetchData = (
    employeeName,
    employeePosition,
    submitted,
    weekBegin,
    approved,
    client,
    order,
    orderBy,
    page,
    rowsPerPage
) => {
    return (dispatch) => {
        const queryParams = {
            skip: page * rowsPerPage,
            max: rowsPerPage,
            employeeName: employeeName,
            employeePosition: employeePosition,
            submitted: submitted,
            weekBegin: weekBegin,
            approved: approved,
            client: client,
            order: order,
            orderBy: orderBy,
        };

        const query = Object.keys(queryParams)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(queryParams[key]))
            .join('&');

        fetchDataByUrl(dispatch, `/time-cards?${query}`);
    };
};
