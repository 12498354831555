import Typography from '@material-ui/core/Typography/Typography';
import React, {Component} from 'react';

class NotFound extends Component {
    render() {
        return (
            <React.Fragment>
                <Typography variant="h4" gutterBottom component="h2">
                    File not found
                </Typography>
            </React.Fragment>
        );
    }
}

export default NotFound;
