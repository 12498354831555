import {
    FETCH_Projects_BEGIN,
    FETCH_Projects_SUCCESS,
    FETCH_Projects_FAILURE
} from './ProjectActions';

const initialState = {
    timeCard: null,
    loading: true,
    error: null,
    items: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_Projects_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
            };

        case FETCH_Projects_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.payload.projects,
            };

        case FETCH_Projects_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };

        default:
            return state;
    }
}
