import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card/Card';
import CardContent from '@material-ui/core/CardContent/CardContent';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Paper from '@material-ui/core/Paper/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table/Table';
import TableBody from '@material-ui/core/TableBody/TableBody';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableHead from '@material-ui/core/TableHead/TableHead';
import TableRow from '@material-ui/core/TableRow/TableRow';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import CircularIndeterminate from '../../component/CircularIndeterminate';
import Typography from '@material-ui/core/Typography/Typography';
import moment from 'moment';
import SearchTimeCardsForm from './../../component/form/SearchTimeCardsForm';
import {fetchData, updateSort} from '../../redux/data/DataActions';
import TablePagination from '@material-ui/core/TablePagination';

const styles = theme => ({
    actions: {
        display: 'flex',
        color: theme.palette.text.secondary,
    },
    filter: {
        margin: theme.spacing.unit * 2,
    },
    link: {
        color: "#101ba5",
    },
    paper: {
        width: '100%',
        minWidth: 500,
    },
    table: {
        minWidth: 700,
    },
    tableCell: {
        paddingTop: 4,
        paddingRight: 24,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    timeCardsHeader: {
        margin: theme.spacing.unit * 2,
        paddingTop: '10px',
    },
});

class List extends Component {
    constructor(props) {
        super(props);

        this.state = {
            labelWidth: 0,
            showTable: !('' === props.employeeName &&
                '' === props.employeePosition &&
                '' === props.submitted &&
                '' === props.weekBegin),
            errorMessage: null,
            role: props.role,
            employeeName: props.employeeName,
            employeePosition: props.employeePosition,
            submitted: props.submitted,
            weekBegin: props.weekBegin,
            approved: props.approved,
            client: props.client,
            orderBy: 'week-begin',
            order: 'asc',
            page: 1,
            rowsPerPage: 10,
        };
    }

    static propTypes = {
        classes: PropTypes.object.isRequired,
        dispatch: PropTypes.func.isRequired,
        items: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
    };

    handleSort = fieldName => () => {
        let order = 'asc';

        if (this.props.orderBy === fieldName && this.props.order === 'asc') {
            order = 'desc';
        }

        this.props.dispatch(updateSort(fieldName, order, this.props.items));
        this.setState({
            runSearch: true,
        })
    };

    createRowClickHandler = (projectId, weekBegin) => () => {
        this.props.history.push(`/time-card/${projectId}/${weekBegin}`);
    };

    handleErrorClose = () => {
        this.setState({
            errorMessage: null,
        });
    };

    submitSearch = () => {
        this.setState({
            runSearch: true,
            showTable: true
        });
    };

    handleSearchTimeCards = values => {
        let weekBegin = '';

        if (undefined !== values.startRange && undefined !== values.endRange) {
            weekBegin = values.startRange.format('MM/DD/YYYY') + '...' + values.endRange.format('MM/DD/YYYY');
        } else if (undefined !== values.startRange) {
            weekBegin = values.startRange.format('MM/DD/YYYY') + '...';
        } else if (undefined !== values.endRange) {
            weekBegin = '...' + values.endRange.format('MM/DD/YYYY');
        }

        this.setState({
            showTable: true,
            runSearch: true,
            employeeName: undefined !== values.employeeName ? values.employeeName : '',
            employeePosition: undefined !== values.employeePosition ? values.employeePosition : '',
            submitted: undefined !== values.submitted ? values.submitted : '',
            weekBegin: weekBegin,
            approved: undefined !== values.approved ? values.approved : '',
            client: undefined !== values.client ? values.client : '',
        });
    };

    updateListSearchCriteria = (employeeName, employeePosition, submitted, weekBegin, approved, client) => {
        this.setState({
            runSearch: true,
            employeeName: employeeName,
            employeePosition: employeePosition,
            submitted: submitted,
            weekBegin: weekBegin,
            approved: approved,
            client: client,
        });
    };

    render() {
        const {classes} = this.props;
        const {errorMessage} = this.state;

        const fieldNames = [
            "employeeId",
            "employeeName",
            "projectId",
            "client",
            "position",
            "week-begin",
            "total-hours",
            "total-standard-time-hours",
            "total-overtime-hours",
            "total-double-time-hours",
            "submitted",
            "approved",
            "locked"
        ];

        const labels = {
            "employeeId": "Employee ID",
            "employeeName": "Employee Name",
            "projectId": "Project",
            "client": "Client",
            "position": "Position",
            "week-begin": "Week Begin",
            "total-hours": "Total Hours",
            "total-standard-time-hours": "Total Standard Time Hours",
            "total-overtime-hours": "Total Overtime Hours",
            "total-double-time-hours": "Total Double Time Hour",
            "submitted": "Submitted?",
            "approved": "Approved?",
            "locked": "Locked?",
        };

        let table = this.renderTable(fieldNames, labels);

        return (
            <React.Fragment>
                <Card>
                    <CardContent>
                        <Typography variant="h5" gutterBottom component="h5">Welcome</Typography>
                        <Typography variant="body1" gutterBottom>
                            Welcome to eTime, ATR's web-based timekeeping system. Please note that approved timecards
                            are due every Monday at 5pm. Questions? Please <a href="mailto:eTime@atr1.com"
                                                                              className={classes.link}>email</a> us or
                            call the eTime hotline at 408.328.8000.
                        </Typography>
                    </CardContent>
                </Card>

                <br/>

                <SearchTimeCardsForm
                    enableReinitialize={true}
                    onSubmit={this.handleSearchTimeCards}
                    updateListSearchCriteria={this.updateListSearchCriteria}
                />

                {table}

                <Dialog
                    open={null !== errorMessage}
                    onClose={this.handleErrorClose}
                >
                    <DialogContent>
                        <DialogContentText>
                            {errorMessage}
                        </DialogContentText>
                        <DialogActions>
                            <Button onClick={this.handleErrorClose} color="primary" autoFocus>Dismiss</Button>
                        </DialogActions>
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        );
    }

    handleChangePage = (event, page) => {
        this.setState({
            page: page,
        });
    };

    handleChangeRowsPerPage = event => {
        this.setState({
            rowsPerPage: event.target.value,
        });
    };

    renderTable(fieldNames, labels) {
        const {classes, items, loading} = this.props;
        const {showTable, rowsPerPage, page} = this.state;

        if (!items.success && undefined !== items.message) {
            return <TableRow>
                <TableCell colSpan={fieldNames.length}>
                    {items.message}
                </TableCell>
            </TableRow>
        }

        let timeCardList = (undefined === items || items.data.length === 0) ?
            <TableRow>
                <TableCell colSpan={fieldNames.length}>
                    {(() => {
                        if (loading) {
                            return <CircularIndeterminate/>;
                        } else {
                            return 'No records meet the criteria.';
                        }
                    })()}
                </TableCell>
            </TableRow> :
            items.data.map(datum => {
                return <TableRow key={datum.timeCardId}
                                 onClick={this.createRowClickHandler(datum.projectId, moment(datum['week-begin'], 'MM/DD/YYYY').format('YYYY-MM-DD'))}
                                 hover={true}>
                    {fieldNames.map(fieldName => (
                        <TableCell className={classes.tableCell} key={fieldName}>
                            {(() => {
                                if ('week-begin' === fieldName) {
                                    return moment(datum['week-begin'], 'MM/DD/YYYY').format('MM/DD/YYYY')
                                } else {
                                    return datum[fieldName]
                                }
                            })()}
                        </TableCell>
                    ))}
                </TableRow>
            });

        return <Paper className={classes.paper}>
            {(() => {
                if (showTable) {
                    return <div>
                        <div className={classes.timeCardsHeader}>
                            <Typography variant="h5" gutterBottom component="h5">Time Cards</Typography>
                        </div>

                        <div className={classes.tableWrapper}>
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        {fieldNames.map(fieldName => (
                                            <TableCell className={classes.tableCell} key={fieldName}>
                                                {labels[fieldName]}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {timeCardList}
                                </TableBody>
                            </Table>
                        </div>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={items.totalCount}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            backIconButtonProps={{
                                'aria-label': 'Previous Page',
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Next Page',
                            }}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                    </div>
                }
            })()}
        </Paper>;
    }

    componentDidMount() {
        const {forceSearch} = this.props;

        if (forceSearch) {
            this.submitSearch();
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.forceSearch) {
            this.setState({
                showTable: true,
                runSearch: true,
                employeeName: nextProps.employeeName,
                employeePosition: nextProps.employeePosition,
                submitted: nextProps.submitted,
                weekBegin: nextProps.weekBegin,
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {dispatch, updateSearchCriteria, turnOffForceSearch} = this.props;

        if (this.state.runSearch) {
            this.setState({
                runSearch: false,
            });

            turnOffForceSearch();

            updateSearchCriteria(
                this.state.employeeName,
                this.state.employeePosition,
                this.state.submitted,
                this.state.weekBegin,
                this.state.approved,
                this.state.client
            );

            this.fetchTableData(dispatch);
        }

        if (prevState.page !== this.state.page) {
            this.fetchTableData(dispatch);
        }
    }

    fetchTableData(dispatch) {
        dispatch(fetchData(
            this.state.employeeName,
            this.state.employeePosition,
            this.state.submitted,
            this.state.weekBegin,
            this.state.approved,
            this.state.client,
            this.state.order,
            this.state.orderBy,
            this.state.page,
            this.state.rowsPerPage
        ));
    }
}

const mapStateToProps = function (state) {
    return ({
        client: state.client.client,
        items: state.data.items,
        loading: state.data.loading,
        orderBy: state.data.orderBy,
        order: state.data.order,
        page: state.data.page,
        rowsPerPage: state.data.rowsPerPage,
    })
};

export default connect(mapStateToProps)(withStyles(styles)(List));
