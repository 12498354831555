import {request} from '../../api/ApiRequest';

export const FETCH_TIMECARDS_BEGIN = 'FETCH_TIMECARDS_BEGIN';
export const FETCH_TIMECARDS_SUCCESS = 'FETCH_TIMECARDS_SUCCESS';
export const FETCH_TIMECARDS_FAILURE = 'FETCH_TIMECARDS_FAILURE';

export const fetchTimeCardsBegin = () => ({
    type: FETCH_TIMECARDS_BEGIN,
});

export const fetchTimeCardsSuccess = timeCards => ({
    type: FETCH_TIMECARDS_SUCCESS,
    payload: {timeCards},
});

export const fetchTimeCardsFailure = error => ({
    type: FETCH_TIMECARDS_FAILURE,
    payload: {error},
});

export const fetchTimeCards = () => {
    return dispatch => {
        dispatch(fetchTimeCardsBegin());

        request('get', '/time-cards').then((response) => {
            dispatch(fetchTimeCardsSuccess(response));
        }).catch(error => {
            if (!error.response) {
                dispatch(fetchTimeCardsFailure('Unknown error'));
                return;
            }

            dispatch(fetchTimeCardsFailure(error.response.data));
        });
    };
};

