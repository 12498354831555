import Paper from '@material-ui/core/Paper/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table/Table';
import TableBody from '@material-ui/core/TableBody/TableBody';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableHead from '@material-ui/core/TableHead/TableHead';
import TableRow from '@material-ui/core/TableRow/TableRow';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import CircularIndeterminate from '../../component/CircularIndeterminate';
import {fetchReport} from "../../redux/reports/ReportsActions";

const styles = theme => ({
    paper: {
        width: '100%',
        minWidth: 500,
    },
    headerRow: {
        backgroundColor: 'ltgrey',
    },
    headerCell: {
        fontWeight: 800,
    },
    table: {
        minWidth: 700,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
});

class Reports extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errorMessage: null,
        };
    }

    static propTypes = {
        classes: PropTypes.object.isRequired,
        dispatch: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired,
    };

    render() {
        const fieldNames = [
            "name",
            "id",
            "client-id",
            "week-begin",
            "position",
            "approved",
            "total-hours",
        ];

        const labels = {
            "name": "Employee Full Name",
            "id": "Employee ID",
            "client-id": "Client",
            "week-begin": "Week Begin",
            "position": "Position",
            "approved": "Approved?",
            "total-hours": "Total Hours",
        };

        return (
            <React.Fragment>
                {this.renderTable(fieldNames, labels)}
            </React.Fragment>
        );
    }

    renderTable(fieldNames, labels) {
        const {classes, loading, report} = this.props;

        if (null === report) {
            return
        }

        return <Paper className={classes.paper}>
            {(() => {
                return <div>
                    <div className={classes.tableWrapper}>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    {fieldNames.map(fieldName => (
                                        <TableCell key={fieldName}>
                                            {labels[fieldName]}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {this.renderReportRows(report, fieldNames)}

                                {report.data.length === 0 && (
                                    <TableRow>
                                        <TableCell colSpan={fieldNames.length}>
                                            {(() => {
                                                if (loading) {
                                                    return <CircularIndeterminate/>;
                                                } else {
                                                    return 'No records meet the criteria.';
                                                }
                                            })()}
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </div>
                </div>
            })()}
        </Paper>;
    }

    renderReportRows(report, fieldNames) {
        const {classes} = this.props;
        const rows = [];

        let lastContractorName = null;

        report.data.map((reportRow) => {
                let contractorName = reportRow['contractor-name'];

                if (lastContractorName !== contractorName) {
                    rows.push(
                        <TableRow key={reportRow.id + 'header'} hover={false} className={classes.headerRow}>
                            <TableCell colSpan={fieldNames.length} className={classes.headerCell}>
                                {contractorName}
                            </TableCell>
                        </TableRow>
                    );
                }

                rows.push(
                    <TableRow key={reportRow.id} hover={true}>
                        {fieldNames.map(fieldName => (
                            <TableCell key={fieldName}>
                                {reportRow[fieldName]}
                            </TableCell>
                        ))}
                    </TableRow>
                );

                lastContractorName = contractorName;

                return reportRow;
            }
        );

        return rows;
    }

    componentDidMount() {
        const {dispatch} = this.props;

        dispatch(fetchReport());
    }
}

const mapStateToProps = function (state) {
    return ({
        loading: state.report.loading,
        error: state.report.error,
        report: state.report.timeCard,
    })
};

export default connect(mapStateToProps)(withStyles(styles)(Reports));
