import {
    FETCH_CLIENT_BEGIN,
    FETCH_CLIENT_SUCCESS,
    FETCH_CLIENT_FAILURE
} from './ClientActions';

const initialState = {
    client: null,
    loading: true,
    error: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CLIENT_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
            };

        case FETCH_CLIENT_SUCCESS:
            return {
                ...state,
                loading: false,
                client: action.payload.client,
            };

        case FETCH_CLIENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };

        default:
            return state;
    }
}
