import {combineReducers} from 'redux';
import client from './client/ClientReducer';
import timeCard from './time-card/TimeCardReducer';
import timeCards from './time-cards/TimeCardsReducer';
import report from './reports/ReportsReducer';
import projects from './projects/ProjectReducer';
import employees from './employees/EmployeeReducer';
import {reducer as oidc} from 'redux-oidc';
import data from './data/DataReducer';
import {reducer as formReducer} from 'redux-form';

export default combineReducers({
    oidc,
    client,
    projects,
    employees,
    data,
    timeCard,
    timeCards,
    report,
    form: formReducer
});
