import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import {createMuiTheme} from '@material-ui/core';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import React from 'react';
import {OidcProvider} from 'redux-oidc';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import App from './App';
import * as serviceWorker from './serviceWorker';
import MomentUtils from '@date-io/moment';
import {MuiPickersUtilsProvider} from 'material-ui-pickers';
import userManager from './utils/userManager';
import store from './store';

window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#5e68df'
        },
        secondary: {
            main: '#efefef',
        },
    },
    overrides: {
        MuiButton: {
            root: {
                margin:'5px',
                background: '#d1d1d1',
                color: 'white',
                '&:hover': {
                    backgroundColor: 'purple'
                }
            }
        }
    },
});

ReactDOM.render(
    <MuiPickersUtilsProvider utils={MomentUtils}>
        <MuiThemeProvider theme={theme}>
            <Provider store={store}>
                <OidcProvider store={store} userManager={userManager}>
                    <App/>
                </OidcProvider>
            </Provider>
        </MuiThemeProvider>
    </MuiPickersUtilsProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
