import {request} from '../../api/ApiRequest';

export const FETCH_REPORT_BEGIN = 'FETCH_REPORT_BEGIN';
export const FETCH_REPORT_SUCCESS = 'FETCH_REPORT_SUCCESS';
export const FETCH_REPORT_FAILURE = 'FETCH_REPORT_FAILURE';

export const fetchReportBegin = () => ({
    type: FETCH_REPORT_BEGIN,
});

export const fetchReportSuccess = report => ({
    type: FETCH_REPORT_SUCCESS,
    payload: {report},
});

export const fetchReportFailure = error => ({
    type: FETCH_REPORT_FAILURE,
    payload: {error},
});

export const fetchReport = () => {
    return dispatch => {
        dispatch(fetchReportBegin());

        request('get', '/report/').then((response) => {
            dispatch(fetchReportSuccess(response.data));
        }).catch(error => {
            if (!error.response) {
                dispatch(fetchReportFailure('Unknown error'));
                return;
            }

            dispatch(fetchReportFailure(error.response.data));
        });
    };
};
