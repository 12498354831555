import React from 'react';
import {Route, Switch} from 'react-router';
import {BrowserRouter} from 'react-router-dom';
import Callback from './page/Callback';
import Guard from './page/Guard';
import NotFound from './page/NotFound';
import Signout from './page/Signout';
import SignoutCallback from './page/SignoutCallback';
import SilentRenew from './page/SilentRenew';

class App extends React.Component {
    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route path="/callback" exact component={Callback} />
                    <Route path="/signout" exact component={Signout} />
                    <Route path="/signout-callback" exact component={SignoutCallback} />
                    <Route path="/silent-renew" exact component={SilentRenew} />
                    <Route path="/" component={Guard} />
                    <Route path="*" exact component={NotFound} />
                </Switch>
            </BrowserRouter>
        );
    }
}

export default App;
