import React from 'react';
import {Typography} from '@material-ui/core';
import {change, Field, reduxForm} from 'redux-form'
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import withStyles from "@material-ui/core/styles/withStyles";
import Button from '@material-ui/core/Button/Button';
import Card from '@material-ui/core/Card/Card';
import CardContent from '@material-ui/core/CardContent/CardContent';
import {DatePicker} from "material-ui-pickers";
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField'
import {Link} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';

const renderTextField = ({
                             input,
                             label,
                             meta: {touched, error},
                             ...custom
                         }) => (
    <div>
        <TextField
            type="text"
            label={label}
            placeholder={label}
            {...input}
            {...custom}
            error={(undefined !== error)}
        />
        {(undefined !== error) ? <FormHelperText>{error}</FormHelperText> : ''}
    </div>
);

const renderSelectField = ({
                               input,
                               label,
                               meta: {touched, error},
                               children,
                               ...custom
                           }) => (
    <div>
        <TextField
            {...input}
            select
            label={label}
            placeholder={label}
            children={children}
            {...custom}
            fullWidth
        />
        {touched && error && <span>{error}</span>}
    </div>
);
const styles = theme => ({
    buttonGroup: {
        textAlign: 'center',
    },
    card: {
        marginBottom: theme.spacing.unit * 2,
    },
    dateRanges: {
        width: '45%',
    },
    formControl: {
        margin: theme.spacing.unit,
        fullWidth: true,
        display: 'flex',
        wrap: 'nowrap',
        minWidth: 120
    },
    paper: {
        padding: 3,
        textAlign: 'center',
    },
    presetButtons: {
        textAlign: 'right',
        marginBottom: '10px'
    },
    root: {
        flexGrow: 1,
    },
    searchLink: {
        border: "2px solid #339933",
        color: "#339933",
        fontWeight: "normal",
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        fontSize: "13px",
        padding: "8px 15px",
        borderRadius: "20px",
        textDecoration: "none",
        textTransform: "uppercase",
        "&:hover": {
            border: "2px solid #33cc00",
            color: "#33cc00"
        },
        '@media (max-width:768px)': {
            fontSize: '16px!important',
            clear: 'both!important',
            float: 'left!important',
            width: 'calc(100% - 8px)!important',
            textAlign: 'center!important',
            maxWidth: '100%!important',
            flexBasis: '100%!important',
            marginBottom: '10px'
        }
    },
    select: {
        minHeight: 50,
    },
    submitButton: {
        color: "white!important",
        background: "#101ba5!important",
        borderRadius: "25px!important",
        padding: "6px 20px!important",
    },
    smallWrapper: {
        '@media (max-width:768px)': {
            clear: 'both!important',
            float: 'left!important',
            maxWidth: '100%!important',
            flexBasis: '100%!important'
        }
    }
});

class SearchTimeCardsForm extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        dispatch: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            labelWidth: 0,
            changeSubmitted: null,
            changeApproved: null,
        };
    }

    renderDateField = ({input, label, meta: {touched, error}, children, ...custom}) => {
        return <div>
            <DatePicker
                {...input}
                label={label}
                placeholder={label}
                {...custom}
                fullWidth
                value={input.value !== '' ? new Date(input.value) : null}
                variant="outlined"
                shouldDisableDate={this.disableDays}
                autoOk={true}
            />
            {touched && error && <span>{error}</span>}
        </div>
    };

    changeSubmitted(status) {
        this.setState({
            changeSubmitted: status
        })
    }

    changeApproved(approved) {
        this.setState({
            changeApproved: approved
        })
    }

    render() {
        const {client, classes, handleSubmit} = this.props;

        return (
            <Card>
                <CardContent>
                    <form name="searchTimeCardsForm" onSubmit={handleSubmit}>
                        {client.role !== 'Manager' && <Grid container spacing={8}>
                            <Grid item xs={12}>
                                <Typography variant="h5" gutterBottom component="h5">Search Time Cards</Typography>
                                <div className={classes.presetButtons}>
                                    <Link to="#" className={classes.searchLink}
                                          onClick={() => this.changeSubmitted('No')}>
                                        Show Unsubmitted Time Cards
                                    </Link>
                                </div>
                            </Grid>
                            <Grid item xs={2} className={classes.smallWrapper}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <Field
                                        label="Start Date"
                                        name="startRange"
                                        component={this.renderDateField}
                                        variant="outlined"
                                        fullWidth
                                    >
                                    </Field>
                                </FormControl>
                            </Grid>
                            <Grid item xs={2} className={classes.smallWrapper}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <Field
                                        label="End Date"
                                        name="endRange"
                                        component={this.renderDateField}
                                        variant="outlined"
                                        fullWidth
                                    >
                                    </Field>
                                </FormControl>
                            </Grid>
                            <Grid item xs={2} className={classes.smallWrapper}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <Field
                                        label="Approved"
                                        placeholder="Approved"
                                        name="approved"
                                        component={renderSelectField}
                                        className={classes.select}
                                        variant="outlined"
                                        fullWidth
                                    >
                                        <MenuItem value=""><em>None</em></MenuItem>
                                        <MenuItem value='Yes' key={'Yes'}>Yes</MenuItem>
                                        <MenuItem value='Not Yet' key={'Not Yet'}>Not Yet</MenuItem>
                                        <MenuItem value='Rejected' key={'Rejected'}>Rejected</MenuItem>
                                    </Field>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4} className={classes.smallWrapper}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <Field
                                        label="Client"
                                        name="client"
                                        component={renderTextField}
                                        variant="outlined"
                                        fullWidth
                                    >
                                    </Field>
                                </FormControl>
                            </Grid>
                            <Grid item xs={2} className={classes.smallWrapper}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <Field
                                        label="Submitted"
                                        name="submitted"
                                        placeholder="Approved"
                                        component={renderSelectField}
                                        fullWidth
                                        className={classes.select}
                                        variant="outlined"
                                    >
                                        <MenuItem value=""><em>None</em></MenuItem>
                                        <MenuItem value='Yes' key={'Yes'}>Yes</MenuItem>
                                        <MenuItem value='No' key={'No'}>No</MenuItem>
                                    </Field>
                                </FormControl>
                            </Grid>
                        </Grid>}

                        {client.role === 'Manager' && <Grid container spacing={8}>
                            <Grid item xs={12}>
                                <Typography variant="h5" gutterBottom component="h5">Search Time Cards</Typography>
                                <div className={classes.presetButtons}>
                                    <Link to="#" className={classes.searchLink}
                                          onClick={() => this.changeApproved('Not Yet')}>
                                        Review Time Cards Pending My Approval
                                    </Link>
                                    &nbsp;&nbsp;
                                    <Link to="#" className={classes.searchLink}
                                          onClick={() => this.changeApproved('Rejected')}>
                                        Review Rejected Time Cards
                                    </Link>
                                </div>
                            </Grid>

                            <Grid item xs={2} className={classes.smallWrapper}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <Field
                                        label="Start Date"
                                        name="startRange"
                                        component={this.renderDateField}
                                        variant="outlined"
                                        fullWidth
                                    >
                                    </Field>
                                </FormControl>
                            </Grid>
                            <Grid item xs={2} className={classes.smallWrapper}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <Field
                                        label="End Date"
                                        name="endRange"
                                        component={this.renderDateField}
                                        variant="outlined"
                                        fullWidth
                                    >
                                    </Field>
                                </FormControl>
                            </Grid>
                            <Grid item xs={2} className={classes.smallWrapper}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <Field
                                        label="Approved"
                                        placeholder="Approved"
                                        name="approved"
                                        component={renderSelectField}
                                        className={classes.select}
                                        variant="outlined"
                                        fullWidth
                                    >
                                        <MenuItem value=""><em>None</em></MenuItem>
                                        <MenuItem value='Yes' key={'Yes'}>Yes</MenuItem>
                                        <MenuItem value='Not Yet' key={'Not Yet'}>Not Yet</MenuItem>
                                        <MenuItem value='Rejected' key={'Rejected'}>Rejected</MenuItem>
                                    </Field>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} className={classes.smallWrapper}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <Field
                                        label="Client"
                                        name="client"
                                        component={renderTextField}
                                        variant="outlined"
                                        fullWidth
                                    >
                                    </Field>
                                </FormControl>
                            </Grid>

                            <Grid item xs={2} className={classes.smallWrapper}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <Field
                                        label="Submitted"
                                        name="submitted"
                                        placeholder="Approved"
                                        component={renderSelectField}
                                        fullWidth
                                        className={classes.select}
                                        variant="outlined"
                                    >
                                        <MenuItem value=""><em>None</em></MenuItem>
                                        <MenuItem value='Yes' key={'Yes'}>Yes</MenuItem>
                                        <MenuItem value='No' key={'No'}>No</MenuItem>
                                    </Field>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4} className={classes.smallWrapper}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <Field
                                        label="Employee Name"
                                        name="employeeName"
                                        component={renderTextField}
                                        fullWidth
                                        variant="outlined"
                                    >
                                    </Field>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} className={classes.smallWrapper}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <Field
                                        label="Position"
                                        name="employeePosition"
                                        component={renderTextField}
                                        fullWidth
                                        variant="outlined"
                                    >
                                    </Field>
                                </FormControl>
                            </Grid>
                        </Grid>}

                        <div className={classes.buttonGroup}>
                            <Button type="submit" className={classes.submitButton}>Search</Button>
                        </div>
                    </form>
                </CardContent>
            </Card>
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {dispatch, updateListSearchCriteria} = this.props;

        if (null !== this.state.changeSubmitted && this.state.changeSubmitted !== prevState.changeSubmitted) {
            dispatch(change('searchTimeCardsForm', 'submitted', this.state.changeSubmitted));

            updateListSearchCriteria('', '', this.state.changeSubmitted, '', '', '');
            this.setState({
                changeSubmitted: null
            });
        }

        if (null !== this.state.changeApproved && this.state.changeApproved !== prevState.changeApproved) {
            dispatch(change('searchTimeCardsForm', 'approved', this.state.changeApproved));

            updateListSearchCriteria('', '', '', '', this.state.changeApproved, '');

            this.setState({
                changeApproved: null
            });
        }
    }
}

let InitializeFromStateForm = reduxForm({
    form: 'searchTimeCardsForm',
})(SearchTimeCardsForm);

const mapStateToProps = function (state) {
    return ({
        client: state.client.client,
        error: state.timeCard.error,
        loading: state.timeCard.loading,
        projects: state.projects.items,
    })
};

export default connect(mapStateToProps)(withStyles(styles)(InitializeFromStateForm));
